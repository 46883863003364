@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');
@import '~antd/dist/antd.less';

body {
  background-color: #f0f0f0;
}

* {
  font-family: 'Noto Sans JP', sans-serif;
}

h1, h2, h3 {
  font-family: 'Lora', serif;
  letter-spacing: 0.02em;
}

.super-bold {
  font-family: 'Montserrat', sans-serif;
}

.ant-layout-sider-children {
  display: flex;
  flex-direction: column;
  .ant-menu {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}

.ant-drawer-body {
  .ant-menu {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-bottom: 16px;
  }
}

.ant-layout-sider-trigger {
  border-right: 1px solid #f0f0f0;
}

.ant-form-item-label {
  label {
    text-transform: uppercase;
    color: #737373;
  }
}
@font-size-base: 13px;@heading-color: #000000;@text-color: #111212;@text-color-secondary: #999;@primary-color: #3bdb95;@padding-lg: 24px;@padding-md: 16px;@padding-sm: 12px;@padding-xs: 8px;@padding-xss: 4px;@height-base: 48px;@height-lg: 40px;@height-sm: 24px;@margin-lg: 24px;@margin-md: 16px;@margin-sm: 12px;@margin-xs: 8px;@margin-xss: 4px;@form-item-label-colon-margin-right: 8px;@form-item-label-colon-margin-left: 2px;